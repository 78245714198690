@font-face {
  /* font-properties */
  font-family: PingFang-SC-Medium;
  src: url("./font/PingFangSCMedium.ttf");
}
@font-face {
  /* font-properties */
  font-family: PingFang-SC-Regular;
  src: url("./font/PingFang-SC-Regular.ttf");
}
@font-face {
  /* font-properties */
  font-family: ArialMT-woff;
  src: url("./font/ArialMT.woff.ttf");
}
.col-333 {
  color: #333333;
}
.col-red{
 color: #E31C17;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70{
  margin-bottom: 70px;
}
.mt-50{
  margin-top: 50px;
}
.mt-150{
  margin-top: 150px;
}
.mt-215{
  margin-top: 215px;
}
.ml-15{
  margin-left: 15px;
}
.ml--25{
  margin-left: -25px;
}
.mr-30{
  margin-right: 30px;
}
.w-445{
  width: 445px;
}
.w-670{
  width: 670px;
}
.w-1200{
  width: 1200px;
}
.margin-auto{
  margin: 0 auto;
}
.position-relative{
  position: relative;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-column{
flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-end{
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}
.title{
  font-family: PingFang-SC-Medium, sans-serif;
  font-size: 64px;
  color: #333333;
  letter-spacing: -1.74px;
  font-weight: bold;
}
.conduction-text{
  font-family: PingFang-SC-Regular,sans-serif;
  font-size: 24px;
  color: #666666;
  letter-spacing: 0.86px;
}
.conduction-text1{
  font-family: PingFang-SC-Regular,sans-serif;
  font-size: 36px;
  color: #333333;
  letter-spacing: 1.29px;
  font-weight: bold;
}
.conduction-text a{
  color: #E31C17;
  text-decoration: none;
}
.conduction-text a:hover{
  text-decoration: underline;
}
.conduction-text-en{
  font-family: ArialMT-woff, sans-serif;
  font-size: 20px;
  color: #666666;
  letter-spacing: 0.71px;
  /*text-align: justify;*/
}
.circle{
  display: inline-block;
  width: 60px;
  height: 60px;
  background: rgba(227,28,23,0.30);
  border-radius: 50%;
}